import React, { Component } from "react";

import AddComponent from "../../components/Banners/Add";
import { connect } from "react-redux";
import Main from "components/layouts/Main";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <AddComponent {...this.props} />
      </Main>

    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
