import React from "react";

import EditComponent from "components/Banners/Edit";
import { useLocation } from "react-router-dom";
import Main from "components/layouts/Main";

export function Edit() {
  const location = useLocation();
  return <Main>
    <EditComponent stateData={location?.state} />;
  </Main>
}

export default Edit;
