import React, { Component } from 'react'

import ListComponent from 'components/Banners/List';
import Main from 'components/layouts/Main';
export class List extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <ListComponent />
            </Main>


        )
    }
}

export default List