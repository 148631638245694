import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getBannersList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}banner_list`,
    data
  );
};

export const AddEditBanners = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}add_edit_banner`,
    data,
    true
  );
};

export const DeleteBanners = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}delete_banner`,
    data
  );
};