import _ from 'lodash';

const DevHost = ["localhost", "betlagao.com", "www.betlagao.com", "user.betlagao.com", "admin.betlagao.com"];
const LiveHost = ["top1010.pro", "top1010.in", "www.top1010.pro", "www.top1010.in"];
const StagHost = ["top2020.pro", "top2020.in", "www.top2020.pro", "www.top2020.in"];

const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://adminapi.top1010.pro/api/",
  baseurl: "https://adminapi.top1010.pro/",
  socketurl: "https://node.top1010.pro",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://adminapi.betlagao.com/api/",
  baseurl: "https://adminapi.betlagao.com/",
  socketurl: "https://node.betlagao.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const stag = {
  api_baseurl: "https://adminapi.top2020.pro/api/",
  baseurl: "https://adminapi.top2020.pro/",
  socketurl: "https://node.top2020.pro",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const AppConfig = _.includes(LiveHost, hostname) ? live : (_.includes(StagHost, hostname) ? stag : dev);

// const AppConfig =  _.includes(DevHost, hostname)? dev: live;

export default AppConfig;