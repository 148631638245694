import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AddEditBanners } from "../../_services/banners.services";
import { toast } from "react-toastify";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function Add(props) {
  const history = useHistory();

  const [link, setLink] = useState("");
  const [banner, setBanner] = useState(null);
  const [banner_image, setBannerImage] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setBannerImage(URL.createObjectURL(e.target.files[0]));
    setBanner(e.target.files[0]);
  }


  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    if (
      link === "" ||
      banner === null
    ) {
      setLoading(false)
      toast.error(
        <div>
          <i
            className="fa fa-times"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
            aria-hidden="true"
          ></i>
          <span>All filds required</span>
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      // toast.error("All filds required");
    } else {
      let sendObj = {};
      sendObj.link = link;
      sendObj.banner = banner;

      let formData = new FormData();
      formData.append('link', sendObj.link);
      formData.append('banner', sendObj.banner);

      AddEditBanners(formData)
        .then((response) => {
          setLoading(false)
          history.push('/banners');

        })


        .catch((error) => {
          setLoading(false)
          toast.error(
            <div>
              <i
                className="fa fa-times"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>Something went wrong. please try again.</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // toast.error(" The username field must contain a unique value");
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Add Banners"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Banners List", url: "/banners" },
        ]}
        currenPageTitle="Add Banners"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form autoComplete="off" enctype='multipart/form-data'>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Link
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="link"
                            className="form-control"
                            placeholder="Enter Link"
                            value={link}
                            autoComplete="off"
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Banner
                        </label>
                        <div className="col-sm-10">
                          <input class="image_upload5"
                            type="file"
                            name="bank_image[]"
                            id="bank_image"
                            placeholder=" "
                            accept="image/png,image/jpg, image/jpeg, image/webp"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="dangerTxt">*  Allowed file type JPG,JPEG,PNG</div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                        </label>
                        <div className="col-sm-10">
                          <div className="d-flex ">
                            {banner_image && <img src={banner_image} width={400} height={200} style={{ margin: "5px" }} />}
                          </div>
                        </div>
                      </div>

                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <Link
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          to="/banners"
                        >
                          Cancel
                        </Link>
                        <button
                          disable={loading}
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <div style={{
        display: 'flex', width: '100%', height: '100%',
        background: "rgba(0,0,0,0.5)",
        alignItems: 'center', justifyContent: 'center',
        position: 'fixed',
        top: 0, left: 0, zIndex: 10000, color: '#fff', fontSize: '15px'
      }}>Uploading...</div>}
    </>
  );
}
